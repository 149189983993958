import React from 'react';
import { Button } from "./ui/button";
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();

  return (
    <header className="fixed top-0 left-0 right-0 z-50 bg-[#f4f4f4] border-b border-gray-200">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center h-16">
          <span 
            className="text-2xl font-bold text-[#171717] cursor-pointer" 
            onClick={() => navigate('/')}
          >
            The Well
          </span>
          <div className="flex items-center gap-4">
            <Button 
              variant="primary"
              className="bg-[#294897] hover:bg-[#1e3670] text-white text-base py-2 px-4 h-auto"
              onClick={() => navigate('/signup')}
            >
              Sign Up
            </Button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
