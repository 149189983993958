import axios from 'axios';

const API_URL = 'https://api.openai.com/v1/chat/completions';

export const generateChatCompletion = async (messages) => {
  try {
    const response = await axios.post(API_URL, {
      model: "gpt-4o-mini",
      messages: messages,
      max_tokens: 800,
      temperature: 0.7,
    }, {
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
        'Content-Type': 'application/json',
      }
    });
    return response.data.choices[0].message.content;
  } catch (error) {
    console.error('Error calling OpenAI:', error);
    throw error;
  }
};