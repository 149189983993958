import React, { useState } from 'react';
import { Button } from "./ui/button";
import { X } from 'lucide-react';
import { useAuthContext } from '../contexts/AuthContext';

const LoginPopup = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login, signup } = useAuthContext(); // Add signup here

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await login(email, password);
      onClose();
    } catch (error) {
      console.error('Login error:', error);
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      await signup(email, password); // Use signup function here
      onClose();
    } catch (error) {
      console.error('Signup error:', error);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-8 w-full max-w-md relative shadow-lg">
        <button 
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
        >
          <X size={24} />
        </button>
        
        <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center font-heading">Welcome back</h2>
        <p className="text-gray-600 text-center mb-8 font-body">
          Log in or sign up to get smarter responses, analyze your witnesses, and more.
        </p>
        
        <Button 
          onClick={handleLogin} 
          className="w-full mb-4 bg-blue-500 text-white hover:bg-blue-600"
        >
          Log in
        </Button>
        
        <Button 
          onClick={handleSignup} 
          className="w-full mb-4 bg-white text-blue-500 border border-blue-500 hover:bg-blue-50"
        >
          Sign up
        </Button>
        
        <button 
          onClick={onClose}
          className="w-full text-gray-500 hover:text-gray-700 text-sm"
        >
          Stay logged out
        </button>
      </div>
    </div>
  );
};

export default LoginPopup;
