import './index.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import LandingPage from './components/LandingPage';
import ChatPage from './components/ChatPage';
import SignUpPage from './components/SignUpPage';
import LoginPage from './components/LoginPage';
import { AuthProvider } from './contexts/AuthContext';
import ChurchesPage from './components/ChurchesPage';

function App() {
  return (
    <Router>
      <AuthProvider>
        <Layout>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/signup" element={<SignUpPage />} />
            {/* Login route commented out for now */}
            {/* <Route path="/login" element={<LoginPage />} /> */}
            <Route path="/churches" element={<ChurchesPage />} />
          </Routes>
        </Layout>
      </AuthProvider>
    </Router>
  );
}

export default App;
