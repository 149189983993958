import React, { useState, useEffect } from 'react';
import { useAuthContext } from '../contexts/AuthContext';
import SidePanel from './SidePanel';

const Layout = ({ children }) => {
  const { user } = useAuthContext();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  return (
    <div className="flex min-h-screen">
      {user && <SidePanel isOpen={isSidePanelOpen} onClose={toggleSidePanel} />}
      <div className={`flex-grow overflow-y-auto transition-all duration-300 ${isSidePanelOpen && !isMobile ? 'ml-64' : ''}`}>
        {children}
      </div>
    </div>
  );
};

export default Layout;
