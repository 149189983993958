import { collection, doc, setDoc, getDoc, updateDoc, query, where, getDocs, deleteDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { scenarios } from './scenarios';

export const createNewChat = (chatId, scenarioId, scenarioTitle) => {
  const scenario = scenarios.find(s => s.id === scenarioId);
  
  if (!scenario) {
    console.error(`Scenario with id ${scenarioId} not found`);
    return null;
  }
  
  let initialMessage = "Welcome to the chat!";
  if (scenario.initialMessages && scenario.initialMessages.length > 0) {
    const randomIndex = Math.floor(Math.random() * scenario.initialMessages.length);
    initialMessage = scenario.initialMessages[randomIndex];
  }

  const newChat = {
    id: chatId,
    scenario: {
      id: scenarioId,
      title: scenarioTitle
    },
    messages: [{ sender: 'The Well', content: initialMessage }],
    createdAt: new Date().toISOString()
  };

  // Save the new chat to local storage or your backend
  saveChat(chatId, newChat);

  return newChat;
};

export const getChat = async (chatId) => {
  if (auth.currentUser) {
    const docRef = doc(db, 'chats', chatId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const chatData = { id: docSnap.id, ...docSnap.data() };
      if (!chatData.messages || chatData.messages.length === 0) {
        const scenario = scenarios.find(s => s.id === chatData.scenario.id);
        if (scenario && scenario.initialMessages && scenario.initialMessages.length > 0) {
          const randomIndex = Math.floor(Math.random() * scenario.initialMessages.length);
          chatData.messages = [{ sender: 'The Well', content: scenario.initialMessages[randomIndex] }];
        } else {
          chatData.messages = [{ sender: 'The Well', content: "Welcome to the chat! How can I help you today?" }];
        }
      }
      return chatData;
    }
    return null;
  } else {
    const guestChats = JSON.parse(localStorage.getItem('guestChats') || '[]');
    const chat = guestChats.find(chat => chat.id === chatId);
    if (chat && (!chat.messages || chat.messages.length === 0)) {
      const scenario = scenarios.find(s => s.id === chat.scenario.id);
      if (scenario && scenario.initialMessages && scenario.initialMessages.length > 0) {
        const randomIndex = Math.floor(Math.random() * scenario.initialMessages.length);
        chat.messages = [{ sender: 'The Well', content: scenario.initialMessages[randomIndex] }];
      } else {
        chat.messages = [{ sender: 'The Well', content: "Welcome to the chat! How can I help you today?" }];
      }
    }
    return chat || null;
  }
};

export const saveChat = async (chatId, messages) => {
  if (auth.currentUser) {
    const docRef = doc(db, 'chats', chatId);
    await updateDoc(docRef, { messages });
  } else {
    const guestChats = JSON.parse(localStorage.getItem('guestChats') || '[]');
    const chatIndex = guestChats.findIndex(chat => chat.id === chatId);
    if (chatIndex !== -1) {
      guestChats[chatIndex].messages = messages;
    } else {
      guestChats.push({ id: chatId, messages });
    }
    localStorage.setItem('guestChats', JSON.stringify(guestChats));
  }
};

export const generateResponse = async (message, conversation, scenarioId) => {
  // Implement your AI response generation logic here
  // This is a placeholder implementation
  return "This is a placeholder response. Implement your AI logic here.";
};

// Add any other necessary functions for chat management

export const getUserChats = async () => {
  if (!auth.currentUser) {
    console.log("No authenticated user");
    return JSON.parse(localStorage.getItem('guestChats') || '[]');
  }
  try {
    const q = query(collection(db, 'chats'), where('userId', '==', auth.currentUser.uid));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error('Error fetching user chats:', error);
    return [];
  }
};

export const updateChatTitle = async (chatId, newTitle) => {
  if (auth.currentUser) {
    const docRef = doc(db, 'chats', chatId);
    await updateDoc(docRef, { title: newTitle });
  } else {
    const guestChats = JSON.parse(localStorage.getItem('guestChats') || '[]');
    const chatIndex = guestChats.findIndex(chat => chat.id === chatId);
    if (chatIndex !== -1) {
      guestChats[chatIndex].title = newTitle;
      localStorage.setItem('guestChats', JSON.stringify(guestChats));
    }
  }
};

// Keep any other existing functions from the original chatUtils.js file
// For example, if you had functions like these, make sure to keep them:

export const deleteChat = async (chatId) => {
  if (auth.currentUser) {
    await deleteDoc(doc(db, 'chats', chatId));
  } else {
    const guestChats = JSON.parse(localStorage.getItem('guestChats') || '[]');
    const updatedChats = guestChats.filter(chat => chat.id !== chatId);
    localStorage.setItem('guestChats', JSON.stringify(updatedChats));
  }
};

export const getAllChats = async () => {
  if (auth.currentUser) {
    const q = query(collection(db, 'chats'), where('userId', '==', auth.currentUser.uid));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } else {
    return JSON.parse(localStorage.getItem('guestChats') || '[]');
  }
};

// Add any other functions that were in the original file but not shown here