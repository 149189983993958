import React from 'react';

const Footer = () => {
  return (
    <footer className="fixed bottom-0 w-full bg-[#f4f4f4] py-2 border-t border-gray-200">
      <div className="container mx-auto px-4 md:px-12 flex justify-center items-center">
        <p className="text-[#171717] text-sm">
          Built by{' '}
          <a 
            href="https://gcflabs.ai" 
            target="_blank" 
            rel="noopener noreferrer"
            className="text-[#294897] hover:text-[#1e3670] font-medium"
          >
            GCFLabs.ai
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
