import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { X, MoreVertical } from 'lucide-react';
import { getUserChats, updateChatTitle } from '../utils/chatUtils';
import { useAuthContext } from '../contexts/AuthContext'; // Update this line

export default function SidePanel({ isOpen, onClose }) {
  const [chats, setChats] = useState([]);
  const [editingChatId, setEditingChatId] = useState(null);
  const [newTitle, setNewTitle] = useState('');
  const navigate = useNavigate();
  const { chatId: currentChatId } = useParams();
  const { user } = useAuthContext(); // Update this line

  useEffect(() => {
    const fetchChats = async () => {
      try {
        const loadedChats = await getUserChats();
        if (Array.isArray(loadedChats) && loadedChats.length > 0) {
          const sortedChats = loadedChats.sort((a, b) => {
            const dateA = a.createdAt ? new Date(a.createdAt) : new Date(0);
            const dateB = b.createdAt ? new Date(b.createdAt) : new Date(0);
            return dateB - dateA;
          });
          setChats(sortedChats);
        } else {
          console.log('No chats found or loadedChats is not an array');
          setChats([]);
        }
      } catch (error) {
        console.error('Error fetching chats:', error);
        setChats([]);
      }
    };

    if (user) {
      fetchChats();
    }
  }, [user]);

  const handleChatSelect = (chatId) => {
    navigate(`/chat/${chatId}`);
    onClose();
  };

  const handleRename = (chatId, currentTitle) => {
    setEditingChatId(chatId);
    setNewTitle(currentTitle);
  };

  const SidePanel = ({ isOpen, onClose }) => {
    if (!isOpen) return null;
  
    return (
      <div className="bg-white w-64 h-full shadow-lg">
        <button onClick={onClose}>close</button>
        {/* Add your side panel content here */}
      </div>
    );
  };

  const handleRenameSubmit = (chatId) => {
    if (newTitle.trim()) {
      updateChatTitle(chatId, newTitle);
      setChats(chats.map(chat => 
        chat.id === chatId ? { ...chat, title: newTitle } : chat
      ));
    }
    setEditingChatId(null);
  };

  return (
    <div className={`fixed top-0 left-0 h-full w-64 bg-[#F3F1FE] text-[#1E1E1E] shadow-lg overflow-hidden z-50 transition-transform duration-300 ${isOpen ? 'translate-x-0' : '-translate-x-full'}`}>
      <div className="flex flex-col h-full">
        <div className="p-4 flex justify-between items-center border-b border-gray-300">
          <h2 className="text-xl font-bold">chat history</h2>
          <button onClick={onClose}><X size={24} /></button>
        </div>
        <div className="flex-grow overflow-y-auto">
          {chats.map(chat => (
            <div 
              key={chat.id} 
              className={`group flex items-center justify-between p-2 cursor-pointer ${
                chat.id === currentChatId ? 'bg-[#E6E1FC]' : 'hover:bg-[#E6E1FC]'
              }`}
              onClick={() => handleChatSelect(chat.id)}
            >
              {editingChatId === chat.id ? (
                <input
                  type="text"
                  value={newTitle}
                  onChange={(e) => setNewTitle(e.target.value)}
                  onBlur={() => handleRenameSubmit(chat.id)}
                  onKeyPress={(e) => e.key === 'Enter' && handleRenameSubmit(chat.id)}
                  className="bg-[#E6E1FC] text-[#1E1E1E] p-1 rounded w-full"
                  autoFocus
                />
              ) : (
                <>
                  <span className="truncate flex-grow">{chat.title || chat.scenario.title}</span>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRename(chat.id, chat.title || chat.scenario.title);
                    }}
                    className="opacity-0 group-hover:opacity-100 transition-opacity"
                  >
                    <MoreVertical size={18} />
                  </button>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
