import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Card, CardHeader, CardTitle, CardContent, CardDescription, CardFooter } from "./ui/card";
import { ArrowUp, ChevronDown, MessageCircle, Heart, BookOpen, Target, Zap, RefreshCw, Send } from 'lucide-react';
import { generateResponse } from '../utils/ConversationManager';
import AnalysisPopup from './AnalysisPopup';
import LoginPopup from './LoginPopup';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useAuthContext } from '../contexts/AuthContext';
import { createNewChat, saveChat } from '../utils/chatUtils';
import Header from './Header';
import Footer from './Footer';
import { cn } from '../lib/utils';
import { checkAndIncrementMessageCount } from '../utils/rateLimiter';

const scenarios = [
  { 
    id: 'jehovah',
    title: "Jehovah's Witness",
    description: "Engage with a Jehovah's Witness",
    initialMessage: "Have you ever wondered why God's true name, Jehovah, is so often overlooked in mainstream Christianity? Let me show you what the Bible really says about it. Why do you think the name Jehovah is not commonly used in many Christian circles?"
  },
  {
    id: 'agnostic',
    title: "Agnostic",
    description: "Discuss with an agnostic viewpoint",
    initialMessage: "As an agnostic, I'm curious about your beliefs. What convinces you that your faith is the correct one among so many religions in the world?"
  },
  {
    id: 'atheist',
    title: "Atheist",
    description: "Converse from an atheist standpoint",
    initialMessage: "I don't believe in any gods. What evidence do you have that your god exists and why should I consider it?"
  },
];

const LandingPage = () => {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [selectedScenario, setSelectedScenario] = useState(null);
  const [showAnalysis, setShowAnalysis] = useState(false);
  const [analysisMessage, setAnalysisMessage] = useState('');
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [inputHeight, setInputHeight] = useState('auto');
  const [currentChatId, setCurrentChatId] = useState(null);
  const [showScenarioSelection, setShowScenarioSelection] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const chatRef = useRef(null);
  const textareaRef = useRef(null);
  const [isChatStarted, setIsChatStarted] = useState(false);
  const messagesEndRef = useRef(null);
  const scenarioRef = useRef(null);
  const [timeRemaining, setTimeRemaining] = useState(300); // 5 minutes in seconds
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [showBetaModal, setShowBetaModal] = useState(false);

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  const handleScenarioSelect = (scenario) => {
    setSelectedScenario(scenario);
    setIsDropdownOpen(false);
    setMessages([{ sender: 'The Well', content: scenario.initialMessage }]);
    setCurrentChatId(uuidv4());
  };

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (inputMessage.trim() === '') return;
    
    const userMessage = { content: inputMessage };
    const updatedMessages = [...messages, userMessage];
    setMessages(updatedMessages);
    setInputMessage('');
    
    try {
      const aiResponse = await generateResponse(inputMessage, updatedMessages, selectedScenario.id);
      const finalMessages = [...updatedMessages, { 
        sender: selectedScenario.title, 
        content: aiResponse 
      }];
      setMessages(finalMessages);
      if (currentChatId) {
        saveChat(currentChatId, finalMessages);
      }
    } catch (error) {
      console.error('Error in handleSubmit:', error);
    }
  }, [inputMessage, messages, selectedScenario, currentChatId]);

  const resetConversation = useCallback(() => {
    setIsChatStarted(false);
    setMessages([]);
    setTimeRemaining(300); // Reset timer to 5 minutes
    setIsTimerRunning(false); // Stop the timer
  }, []);

  const handleInputChange = (e) => {
    setInputMessage(e.target.value);
    setInputHeight('auto');
    setInputHeight(`${e.target.scrollHeight}px`);
  };

  const handleInsights = useCallback(() => {
    if (messages.length < 5) {
      setAnalysisMessage("Continue the conversation to get more insightful analysis. We recommend at least 5 messages for a meaningful review.");
    } else {
      setAnalysisMessage('');
    }
    setShowAnalysis(true);
  }, [messages]);

  const handleStartChat = useCallback(() => {
    setIsChatStarted(true);
    setIsTimerRunning(true);
    setTimeRemaining(300); // Reset timer when starting new conversation
    setMessages([{ 
      sender: selectedScenario.title,
      content: selectedScenario.initialMessage 
    }]);
    
    setTimeout(() => {
      if (chatRef.current) {
        chatRef.current.scrollIntoView({ 
          behavior: 'smooth',
          block: 'start'
        });
      }
    }, 100);
  }, [selectedScenario]);

  const handleStartConversation = useCallback(() => {
    const jehovahScenario = scenarios.find(s => s.id === 'jehovah');
    setSelectedScenario(jehovahScenario);
    setIsChatStarted(false);
    setMessages([]);
    
    setTimeout(() => {
      if (scenarioRef.current) {
        scenarioRef.current.scrollIntoView({ 
          behavior: 'smooth',
          block: 'end'
        });
      }
    }, 100);
  }, []);

  const handleAnalyze = () => {
    setShowAnalysis(true);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    if (isTimerRunning && timeRemaining > 0) {
      const timer = setInterval(() => {
        setTimeRemaining(prev => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [isTimerRunning, timeRemaining]);

  return (
    <div className="min-h-screen bg-[#f4f4f4] text-[#171717] overflow-y-auto">
      <Header />
      <main className="pt-16">
        <section className="container mx-auto px-4 py-20 text-center">
          <h1 className="text-5xl md:text-6xl font-bold mb-6 text-[#171717]">
            Share Your Faith With Confidence
          </h1>
          <p className="text-xl mb-8 max-w-2xl mx-auto text-[#171717]">
            At The Well in Samaria, Jesus answered questions and led a woman to Faith, who then brought her entire city to Him. The Well is your training ground to do the same—learn to defend your Faith and make disciples of nations.
          </p>
          <div className="flex justify-center space-x-4">
            <Button
              className="bg-white text-[#294897] hover:bg-gray-100 text-base py-4 px-6 flex items-center"
              onClick={handleStartConversation}>
              <MessageCircle className="mr-2 h-5 w-5" />
              Try It Now
            </Button>
            <Button
              onClick={() => navigate('/churches')}
              className="bg-[#294897] text-white border-2 border-white hover:bg-[#1c3266] text-base py-4 px-6 flex items-center">
              <Zap className="mr-2 h-5 w-5" />
              For Churches
            </Button>
          </div>
        </section>
      </main>
      {showAnalysis && (
        <AnalysisPopup
          onClose={() => setShowAnalysis(false)}
        />
      )}
      {showLoginPopup && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <LoginPopup
            onClose={() => setShowLoginPopup(false)}
            onLogin={() => {
              setShowLoginPopup(false);
              setShowAnalysis(true);
            }}
          />
        </div>
      )}
      {selectedScenario && (
        <>
          {/* Context Window */}
          <section 
            ref={scenarioRef}
            className="container mx-auto px-4"
            id="context-section"
          >
            <Card className="max-w-[1200px] mx-auto">
              <CardContent className="p-4">
                <h2 className="text-xl font-bold mb-4">Jehovah's Witness</h2>
                <div className="space-y-4">
                  <div>
                    <h3 className="font-bold text-lg mb-2 text-[#294897]">Instructions</h3>
                    <p className="text-gray-700">
                      A Jehovah's Witness has just knocked on your door. You have 5 minutes to engage 
                      in a conversation and share the gospel. Remember to be respectful while discussing 
                      biblical truth about Jesus's deity and salvation through grace alone.
                    </p>
                  </div>
                  <div>
                    <h3 className="font-bold text-lg mb-2 text-[#294897]">Their Key Beliefs</h3>
                    <ul className="list-disc list-inside text-gray-700 space-y-2">
                      <li>Use of God's name "Jehovah"</li>
                      <li>Jesus as God's first creation</li>
                      <li>144,000 chosen for heaven</li>
                      <li>No blood transfusions</li>
                    </ul>
                  </div>
                  <div>
                    <h3 className="font-bold text-lg mb-2 text-[#294897]">Ready?</h3>
                    <p className="text-gray-700 mb-4">
                      Click start when you're ready to begin the conversation. Remember, you'll have 5 minutes 
                      to share the truth of the gospel.
                    </p>
                    {!isTimerRunning ? (
                      <Button
                        onClick={handleStartChat}
                        className="bg-[#294897] hover:bg-[#1c3266] text-white text-sm py-2 px-4 flex items-center justify-center"
                      >
                        Start
                      </Button>
                    ) : (
                      <div className="text-xl font-bold text-[#294897]">
                        Time Remaining: {formatTime(timeRemaining)}
                      </div>
                    )}
                  </div>
                </div>
              </CardContent>
            </Card>
          </section>

          {/* Chat Window */}
          <section 
            ref={chatRef}
            className="container mx-auto px-4"
            id="chat-section"
          >
            <Card className="max-w-[1200px] mx-auto">
              <CardContent className="p-0">
                <div className="flex flex-col h-[600px]">
                  {/* Chat header */}
                  <div className="flex-shrink-0 flex justify-between items-center py-1.5 px-4 border-b">
                    <button
                      onClick={handleAnalyze}
                      className="text-[#294897] hover:text-[#1c3266] text-sm py-0.5 flex items-center gap-2"
                    >
                      <MessageCircle className="h-4 w-4" />
                      End & Review
                    </button>
                    <button
                      onClick={resetConversation}
                      className="text-[#294897] hover:text-[#1c3266] text-sm py-0.5 flex items-center gap-2"
                    >
                      <RefreshCw className="h-4 w-4" />
                      Restart
                    </button>
                  </div>

                  {/* Chat messages and input */}
                  <div className="flex-1 min-h-0 overflow-hidden">
                    {!isChatStarted ? (
                      <div className="flex flex-col items-center justify-center h-full bg-gray-50 p-4">
                        <h2 className="text-xl md:text-2xl font-bold text-center text-[#171717] mb-4">
                          Press 'Start' to begin the conversation
                        </h2>
                        <p className="text-gray-600 text-center max-w-md px-4">
                          Review the context, then click the Start button when you're ready
                        </p>
                        <Button
                          onClick={handleStartChat}
                          className="mt-4 md:hidden bg-[#294897] hover:bg-[#1c3266] text-white text-sm py-2 px-4"
                        >
                          Start
                        </Button>
                      </div>
                    ) : (
                      <div className="flex flex-col h-full">
                        <div className="flex-1 overflow-y-auto">
                          <div className="space-y-4 p-4">
                            {messages.map((message, index) => (
                              <div
                                key={index}
                                ref={index === messages.length - 1 ? messagesEndRef : null}
                                className={cn(
                                  "flex w-full",
                                  !message.sender ? "justify-end" : "justify-start"
                                )}
                              >
                                <div
                                  className={cn(
                                    "rounded-2xl px-4 py-2 max-w-[80%] relative",
                                    !message.sender ? "bg-[#294897] text-white" : "bg-gray-100 text-gray-900"
                                  )}
                                >
                                  {message.sender && (
                                    <p className="text-sm font-medium mb-1">{message.sender}</p>
                                  )}
                                  <p>{message.content}</p>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="flex-shrink-0 border-t bg-white p-4">
                          <form 
                            onSubmit={handleSubmit} 
                            className="relative"
                          >
                            <div className="relative">
                              <Input
                                value={inputMessage}
                                onChange={handleInputChange}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault();
                                    handleSubmit(e);
                                  }
                                }}
                                placeholder="Type your message..."
                                className="w-full pr-12 rounded-2xl border-gray-200"
                              />
                              <Button 
                                type="submit"
                                className="absolute right-2 top-1/2 -translate-y-1/2 p-1.5 hover:bg-[#1c3266] bg-[#294897] rounded-full"
                                variant="ghost"
                              >
                                <ArrowUp className="h-4 w-4 text-white" />
                              </Button>
                            </div>
                          </form>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </CardContent>
            </Card>
          </section>
        </>
      )}
    </div>
  );
};

export default LandingPage;
