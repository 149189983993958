import React from 'react';
import { Button } from "./ui/button";
import { Mail, MessageCircle } from 'lucide-react';
import Header from './Header';
import Footer from './Footer';

const ChurchesPage = () => {
  const handleContactClick = () => {
    window.location.href = 'mailto:rclnnation@gmail.com?subject=Church Partnership Inquiry';
  };

  return (
    <div className="min-h-screen bg-[#f4f4f4] text-[#171717]">
      <Header />
      <main className="pt-16">
        <section className="container mx-auto px-4 py-20">
          <div className="max-w-4xl mx-auto">
            <h1 className="text-5xl md:text-6xl font-bold mb-6 text-[#171717] text-center">
              Equip Your Church for Digital Evangelism
            </h1>
            
            <p className="text-xl mb-12 text-center text-gray-700">
              In today's digital age, your congregation needs new tools to share their faith effectively. 
              The Well provides AI-powered training that helps your members confidently engage in 
              meaningful spiritual conversations.
            </p>

            <div className="flex justify-center">
              <Button
                onClick={handleContactClick}
                className="bg-white text-[#294897] hover:bg-gray-100 text-base py-4 px-6 flex items-center"
              >
                <MessageCircle className="mr-2 h-5 w-5" />
                Let's Chat
              </Button>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default ChurchesPage; 