import { useState } from 'react';
import { 
  Dialog, 
  DialogContent, 
  DialogHeader, 
  DialogTitle, 
  DialogDescription 
} from "./ui/dialog";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Rocket, Loader2 } from "lucide-react";

const AnalysisPopup = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState('idle');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    
    try {
      const formId = process.env.REACT_APP_CONVERTKIT_FORM_ID;
      const apiKey = process.env.REACT_APP_CONVERTKIT_API_KEY;
      
      const response = await fetch(`https://api.convertkit.com/v3/forms/${formId}/subscribe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          api_key: apiKey,
          email: email,
          first_name: firstName
        }),
      });

      const data = await response.json();
      console.log('ConvertKit Response:', data);

      if (response.ok && data.subscription) {
        setStatus('success');
        setTimeout(() => {
          onClose();
        }, 2000);
      } else {
        console.error('ConvertKit Error:', data);
        setStatus('error');
      }
    } catch (error) {
      console.error('ConvertKit API Error:', error);
      setStatus('error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={true} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle className="text-2xl font-bold text-center mb-2">
            We're Still Building
          </DialogTitle>
          <DialogDescription className="text-center space-y-4">
            <div className="flex justify-center mb-4">
              <Rocket className="h-12 w-12 text-[#294897]" />
            </div>
            <p className="text-lg text-gray-700">
              We promise to grant you first access to analyzing your chats, suggestions to improve your witness, chat history, and many more features when we launch.
            </p>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="space-y-3">
                <Input
                  type="text"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="w-full"
                  required
                />
                <Input
                  type="email"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full"
                  required
                />
              </div>
              <Button 
                type="submit" 
                className="w-full bg-[#294897] hover:bg-[#1e3670] text-white"
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Processing...
                  </>
                ) : (
                  'Request Access'
                )}
              </Button>
              
              {status === 'success' && (
                <p className="text-green-600 text-sm text-center">
                  You're in! We'll notify you as soon as these features are ready.
                </p>
              )}
              {status === 'error' && (
                <p className="text-red-600 text-sm text-center">
                  Oops! Something went wrong. Please try again.
                </p>
              )}
              
              <p className="text-xs text-gray-500 text-center">
                By requesting access, you'll be first to know when new features launch.
              </p>
            </form>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default AnalysisPopup;
