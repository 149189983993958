import React from 'react';

export const Dialog = ({ open, onOpenChange, children }) => {
  if (!open) return null;
  
  return (
    <div className="fixed inset-0 z-50 bg-black/50 flex items-center justify-center">
      <div className="relative bg-white rounded-lg max-w-md w-full mx-4">
        {children}
        <button
          onClick={() => onOpenChange(false)}
          className="absolute right-4 top-4 text-gray-400 hover:text-gray-500"
        >
          ×
        </button>
      </div>
    </div>
  );
};

export const DialogContent = ({ className = "", children }) => (
  <div className={`p-6 ${className}`}>
    {children}
  </div>
);

export const DialogHeader = ({ className = "", children }) => (
  <div className={`space-y-1.5 ${className}`}>
    {children}
  </div>
);

export const DialogTitle = ({ className = "", children }) => (
  <h2 className={`text-lg font-semibold ${className}`}>
    {children}
  </h2>
);

export const DialogDescription = ({ className = "", children }) => (
  <div className={`text-sm text-gray-500 ${className}`}>
    {children}
  </div>
); 