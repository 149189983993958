import React from 'react';
import { cn } from "../../lib/utils"

export function Button({ children, variant = "primary", className, ...props }) {
  const baseClass = variant === "primary" ? "btn-primary" : "btn-secondary";
  
  return (
    <button 
      className={cn(baseClass, className)}
      {...props}
    >
      {children}
    </button>
  );
}
