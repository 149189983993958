import { useState } from 'react';
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Card } from "./ui/card";
import { Rocket, Loader2 } from "lucide-react";
import Header from './Header';
import Footer from './Footer';

const SignupPage = () => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState('idle');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    
    try {
      const formId = process.env.REACT_APP_CONVERTKIT_FORM_ID;
      const apiKey = process.env.REACT_APP_CONVERTKIT_API_KEY;
      
      const response = await fetch(`https://api.convertkit.com/v3/forms/${formId}/subscribe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          api_key: apiKey,
          email: email,
          first_name: firstName
        }),
      });

      const data = await response.json();
      console.log('ConvertKit Response:', data);

      if (response.ok && data.subscription) {
        setStatus('success');
      } else {
        console.error('ConvertKit Error:', data);
        setStatus('error');
      }
    } catch (error) {
      console.error('ConvertKit API Error:', error);
      setStatus('error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-[#f4f4f4] flex flex-col">
      <Header />
      <main className="flex-grow container mx-auto px-4 py-8 pt-24">
        <Card className="max-w-md mx-auto p-8 shadow-lg border border-gray-200 bg-white rounded-lg">
          <div className="text-center space-y-4 mb-8">
            <div className="flex justify-center">
              <Rocket className="h-12 w-12 text-[#294897]" />
            </div>
            <h1 className="text-2xl font-bold text-[#171717]">
              We're Still Building
            </h1>
            <p className="text-lg text-gray-700">
              We promise to grant you first access to analyzing your chats, suggestions to improve your witness, chat history, and many more features when we launch.
            </p>
          </div>

          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="space-y-3">
              <Input
                type="text"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="w-full"
                required
              />
              <Input
                type="email"
                placeholder="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full"
                required
              />
            </div>
            
            <Button 
              type="submit" 
              className="w-full bg-[#294897] hover:bg-[#1e3670] text-white py-6"
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Processing...
                </>
              ) : (
                'Request Access'
              )}
            </Button>
            
            {status === 'success' && (
              <div className="bg-green-50 text-green-600 p-4 rounded-lg text-center">
                You're in! We'll notify you as soon as these features are ready.
              </div>
            )}
            {status === 'error' && (
              <div className="bg-red-50 text-red-600 p-4 rounded-lg text-center">
                Oops! Something went wrong. Please try again or contact support.
              </div>
            )}
            
            <p className="text-xs text-gray-500 text-center">
              By requesting access, you'll be first to know when new features launch. 
              We're committed to protecting your data and privacy.
            </p>
          </form>
        </Card>
      </main>
      <Footer />
    </div>
  );
};

export default SignupPage; 